import {Veranstaltung} from "../common/Veranstaltung";
import {Fragment} from "preact";
import {veranstaltungsDetailUrl} from "../common/settings";
import {Zustandslabel} from "./Zustandslabel";
import {Absagegrund} from "./Absagegrund";
import {KategorienTags} from "./KategorienTags";


export interface VeranstaltungsCardHeaderProps{
    veranstaltung: Veranstaltung
}

export function VeranstaltungsDetailHeader({veranstaltung}: VeranstaltungsCardHeaderProps) {
    return (
        <Fragment>
            <h2><a href={veranstaltungsDetailUrl(veranstaltung.id)}>{veranstaltung.titel}</a></h2>
            <p><Zustandslabel zustand={veranstaltung.zustand}/></p>
            <Absagegrund grund={veranstaltung.absagegrund}/>
            <p><KategorienTags kategorien={veranstaltung.kategorien}/></p>
        </Fragment>
    )
}