import {AnmeldeDaten, AnmeldeFormular} from "./AnmeldeFormular";
import {Guid} from "../common/Veranstaltung";
import {useContext, useState} from "preact/compat";
import {KatalogKontext} from "../common/KatalogKontext";
import {anmeldeApiUrl, kontaktUrl} from "../common/settings";
import {PunktPunktPunkt} from "../common/PunktPunktPunkt";
import {Panel} from "../widgets/Panel";
import {Fragment} from "preact";

interface AnmeldeDatenKomplett extends AnmeldeDaten {
    veranstaltungsId: Guid
}

enum Zustand {
    Eingabe,
    Sende,
    Erfolg,
    Fehler
}

export function AnmeldeZustand({veranstaltungsId}: { veranstaltungsId: Guid }) {
    const {apiServer} = useContext(KatalogKontext);
    const [zustand, setZustand] = useState(Zustand.Eingabe)

    const handleSubmit = async (anmeldeDaten: AnmeldeDaten) => {
        const body: AnmeldeDatenKomplett = {veranstaltungsId: veranstaltungsId, ...anmeldeDaten};

        try {
            setZustand(Zustand.Sende);
            const response = await fetch(anmeldeApiUrl(apiServer), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
                mode: 'cors'
            });
            setZustand(response.ok ? Zustand.Erfolg : Zustand.Fehler);
        } catch {
            setZustand(Zustand.Fehler)
        }
    };

    switch (zustand) {
        case Zustand.Eingabe:
            return (
                <Panel heading='Anmeldeformular'>
                    <AnmeldeFormular onSubmit={handleSubmit}/>
                </Panel>
            )
        case Zustand.Sende:
            return (
                <Panel color='info' heading={<Fragment>Sende Anmeldung <PunktPunktPunkt/></Fragment>}>
                    Ihre Anfrage wird übertragen.
                </Panel>
            );
        case Zustand.Erfolg:
            return (
                <Panel color='success' heading='Anmeldung erfolgreich versendet'>
                    Ihre Anmeldung wurde erfolgreich übermittelt.
                    Sie erhalten in Kürze eine Bestätigungsmail an ihre angegebene E-Mail Adresse.
                </Panel>
            );
        case Zustand.Fehler:
            return (
                <Panel color='danger' heading='Anmeldung fehlgeschlagen'>
                    Ihre Anmeldung konnte leider nicht übermittelt werden. Bitte treten sie mit uns
                    anderweitig in <a href={kontaktUrl}>Kontakt</a>.
                </Panel>
            );
    }
}