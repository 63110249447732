import {Preiskomponente} from "../common/Veranstaltung";
import {Fragment} from "preact";
import {ListeMitÜberschrift} from "../common/ListeMitÜberschrift";

interface Props {
    preis: Preiskomponente[],
    rabatte: string[]
}

export function Investition({preis, rabatte}: Props) {
    return (
        <Fragment>
            <section>
                <h3>Investition</h3>
                <table class="table table-condensed">
                    <tbody>
                    {preis.map(komponente =>
                        <tr>
                            <td>{komponente.kosten}</td>
                            <td>{komponente.beschreibung}</td>
                        </tr>)}
                    </tbody>
                </table>
            </section>
            {rabatte.length > 0 ? <ListeMitÜberschrift überschrift={"Rabatte"} liste={rabatte}/> : null}
        </Fragment>
    )
}