import {PunktPunktPunkt} from "./PunktPunktPunkt";

interface SanduhrProps {
    was: string
}


export function Sanduhr({was} : SanduhrProps) {
    return (
        <div class="text-center">
            <p><span class="glyphicon glyphicon-hourglass"></span></p>
            <p>Lade {was} <PunktPunktPunkt/></p>
        </div>
    );
}