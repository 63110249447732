import {Guid, Kooperation, Veranstaltung} from "../common/Veranstaltung";
import {AnmeldeZustand} from "./AnmeldeZustand";
import {Fragment} from "preact";

function hatBuchungslinkg(veranstaltung: Veranstaltung) {
    return veranstaltung.kooperation.vorhanden && veranstaltung.kooperation.buchungslink;
}

function AnmeldungPerFormular({veranstaltungsId}: {veranstaltungsId: Guid}) {
    return (
        <Fragment>
            <p>
                Mit dem folgenden Formular können sie sich zu dieser Veranstaltung anmelden. Nach erfolgreicher
                Übermittlung Ihrer Daten wird sich ein Mitglied unseres Teams bei ihnen melden,
                um alle Details zu klären.
            </p>
            <AnmeldeZustand veranstaltungsId={veranstaltungsId}/>
        </Fragment>
    )
}

function AnmeldungPerBuchungslink({kooperation}: {kooperation: Kooperation}) {
    return (
        <Fragment>
            Die Anmeldung findet über den <a href={kooperation.buchungslink}>Buchungslink</a> unseres
            Kooperationspartners <a href={kooperation.website}>{kooperation.name}</a> statt.
        </Fragment>
    )
}

export function Anmeldung({veranstaltung}: {veranstaltung: Veranstaltung}) {
    return (
        <section>
            <h3>Anmeldung</h3>
            {hatBuchungslinkg(veranstaltung)
                ? <AnmeldungPerBuchungslink kooperation={veranstaltung.kooperation}/>
                : <AnmeldungPerFormular veranstaltungsId={veranstaltung.id}/>}
        </section>
    )
}