import {Kooperation} from "../common/Veranstaltung";
import {Fragment} from "preact";

export function Kooperationspartner({partner}: { partner: Kooperation }) {
    if (!partner.vorhanden) return null
    return (
        <section>
            {/*<h3>Kooperation</h3>*/}
            <p>
                Die Veranstaltung findet in Kooperation mit unserem
                Partner <a href={partner.website}>{partner.name}</a> statt.
                {partner.buchungslink !== null
                    ? <Fragment>
                        &#32;Die Buchung erfolgt direkt über die Website unseres Partners unter dem
                        folgenden <a href={partner.buchungslink}>Buchungslink</a>.
                    </Fragment> : null}
                {partner.bildungsurlaub
                    ? <Fragment>
                        &#32;Außerdem können sie für diese Veranstaltung Bildungsurlaub beantragen.
                    </Fragment> : null}
            </p>
            <hr/>
        </section>
    )
}