import {StateUpdater, useCallback, useMemo, useState} from "preact/compat";
import {Input} from "./Input";
import {Textarea} from "./Textarea";

type Checked<T> = [T, boolean] | [undefined, undefined]

type CheckedField = [Checked<string>, StateUpdater<string>]

function useCheckedField(regex: RegExp, init: string | undefined = undefined): CheckedField {
    const [state, setState] = useState(init);
    const checked: Checked<string> = useMemo(() => {
        if (state === undefined) return [undefined, undefined];
        return [state, !!state.match(regex)];
    }, [state, regex])
    return [checked, setState]
}

export interface AnmeldeDaten {
    vorname: string
    nachname: string
    email: string
    telefon: string
    anmerkung: string
}

export function AnmeldeFormular({onSubmit}: { onSubmit: (daten: AnmeldeDaten) => void }) {
    const [[vorname, vornameIstGültig], setVorname] = useCheckedField(/^[^\s\d]([^\s\d]|[ ]){1,50}[^\s\d]$/);
    const [[nachname, nachnameIstGültig], setNachname] = useCheckedField(/^[^\s\d]([^\s\d]|[ ]){1,50}[^\s\d]$/);
    const [[email, emailIstGültig], setEmail] = useCheckedField(/^\S{1,50}@\S{1,50}\.\S{1,15}$/);
    const [[telefon, telefonIstGültig], setTelefon] = useCheckedField(/^[0-9 +-/]{3,30}$/);
    const [[anmerkung, anmerkungIstGültig], setAnmerkung] = useCheckedField(/^(?:.|[\n]){0,300}$/, "");

    const allesGültig = useMemo(() =>
            vornameIstGültig && nachnameIstGültig && emailIstGültig && telefonIstGültig && anmerkungIstGültig,
        [vornameIstGültig, nachnameIstGültig, emailIstGültig, telefonIstGültig, anmerkungIstGültig]);

    const buttonClass = useMemo(() => allesGültig ? "btn-success" : "btn-default", [allesGültig]);

    const handleSubmit = useCallback((ev: Event) => {
        ev.preventDefault();
        if (allesGültig) {
            onSubmit({
                vorname: vorname!,
                nachname: nachname!,
                email: email!,
                telefon: telefon!,
                anmerkung: anmerkung!
            });
        }
    }, [vorname, nachname, email, telefon, anmerkung]);

    return (
        <form onSubmit={handleSubmit} class="clearfix">
            <Input type="text" label="Vorname*" placeholder="Max"
                   onChange={setVorname} value={vorname}
                   gültig={vornameIstGültig}/>
            <Input type="text" label="Nachname*" placeholder="Mustermann"
                   onChange={setNachname} value={nachname}
                   gültig={nachnameIstGültig}/>
            <Input type="email" label="Email Adresse*" placeholder="max@mustermann.de"
                   onChange={setEmail} value={email}
                   gültig={emailIstGültig}/>
            <Input type="tel" label="Telefonnummer*" placeholder="12345 678 91 00"
                   onChange={setTelefon} value={telefon}
                   gültig={telefonIstGültig}/>
            <Textarea value={anmerkung} onChange={setAnmerkung}
                      gültig={anmerkungIstGültig}
                      label="Anmerkungen (max. 300 Zeichen)"
                      placeholder="Ihre Anmerkung"
                      rows={3} maxLength={300}/>
            <p>
                <small>(*) Notwendige Felder</small>
            </p>
            <button type="submit" class={"btn " + buttonClass}
                    style="float: right" disabled={!allesGültig}>
                Anmeldung absenden
            </button>
        </form>
    );
}