import {useCallback, useContext, useEffect, useState, useRef} from "preact/compat";
import {KatalogKontext} from "./KatalogKontext";
import {Guid, Trainer, TrainerKomplett, Veranstaltung, VeranstaltungAuszug} from "./Veranstaltung";
import {aktiveTrainerApiUrl, trainerKomplettApiUrl, veranstaltungsAuzügeUrl, veranstaltungsDatenUrl} from "./settings";
import {RefObject} from "preact";
import {erzeugeId} from "./helfer";

export enum LadeZustand {
    Init,
    Lade,
    Fertig,
    Fehler,
    NichtGefunden
}

type LadeKommando = () => Promise<void>


// =========================   Veranstaltungs Auszüge ========================

export type GeladeneVeranstaltungsAuszüge =
    | [LadeZustand.Init]
    | [LadeZustand.Lade]
    | [LadeZustand.Fertig, VeranstaltungAuszug[]]
    | [LadeZustand.Fehler]

export function useVeranstaltungsAuzüge(): [GeladeneVeranstaltungsAuszüge, LadeKommando] {
    const {apiServer} = useContext(KatalogKontext);
    const [geladeneAuszüge, setGeladeneAuszüge] = useState<GeladeneVeranstaltungsAuszüge>([LadeZustand.Init]);
    const [ladeZustand] = geladeneAuszüge;

    const ladeAuszüge = useCallback(async () => {
        if (ladeZustand === LadeZustand.Init) {
            setGeladeneAuszüge([LadeZustand.Lade]);
            try {
                const response = await fetch(veranstaltungsAuzügeUrl(apiServer))
                if (response.ok) {
                    setGeladeneAuszüge([LadeZustand.Fertig, await response.json()])
                } else {
                    setGeladeneAuszüge([LadeZustand.Fehler]);
                }
            } catch {
                setGeladeneAuszüge([LadeZustand.Fehler]);
            }
        }
    }, [ladeZustand, setGeladeneAuszüge, apiServer])

    return [geladeneAuszüge, ladeAuszüge];
}

export function useVeranstaltungsAuszügeSofort() : GeladeneVeranstaltungsAuszüge {
    const [geladeneAuszüge, ladeAuszüge] = useVeranstaltungsAuzüge();
    useEffect(() => {ladeAuszüge();}, [ladeAuszüge]);
    return geladeneAuszüge;
}


// =======================  VeranstaltungsDaten ============================

export type GeladeneVeranstaltungsDaten =
    | [LadeZustand.Init]
    | [LadeZustand.Lade]
    | [LadeZustand.Fertig, Veranstaltung]
    | [LadeZustand.Fehler]
    | [LadeZustand.NichtGefunden]

export function useVeranstaltungsDaten(id: Guid): [GeladeneVeranstaltungsDaten, LadeKommando] {
    const {apiServer} = useContext(KatalogKontext);
    const [veranstaltungsDaten, setVeranstaltungsDaten] = useState<GeladeneVeranstaltungsDaten>([LadeZustand.Init]);
    const [ladeZustand] = veranstaltungsDaten;
    const ladeVeranstaltungsDaten = useCallback(async () => {
        if (ladeZustand === LadeZustand.Init) {
            setVeranstaltungsDaten([LadeZustand.Lade]);
            try {
                const response = await fetch(veranstaltungsDatenUrl(id, apiServer))
                if (response.ok) {
                    setVeranstaltungsDaten([LadeZustand.Fertig, await response.json()])
                } else if (response.status === 404) {
                    setVeranstaltungsDaten([LadeZustand.NichtGefunden])
                } else {
                    setVeranstaltungsDaten([LadeZustand.Fehler]);
                }
            } catch {
                setVeranstaltungsDaten([LadeZustand.Fehler]);
            }
        }
    }, [veranstaltungsDaten, setVeranstaltungsDaten, id, apiServer]);

    return [veranstaltungsDaten, ladeVeranstaltungsDaten];
}

export function useVeranstaltungsDatenSofort(id: Guid) {
    const [veranstaltungsDaten, ladeVeranstaltungsDaten] = useVeranstaltungsDaten(id);
    useEffect(() => {ladeVeranstaltungsDaten();});
    return veranstaltungsDaten;
}


// ==================  Toggler =========================

export function useToggler(startZustand: boolean): [boolean, () => void] {
    const [zustand, setZustand] = useState(startZustand);
    const toggleZustand = useCallback(
        () => setZustand(sichtbar => !sichtbar),
        [zustand, setZustand]
    );

    return [zustand, toggleZustand]
}


// ============== CollapsibleObserver ==================

export enum CollapseState {
    versteckt,
    aktiv,
    sichtbar
}

export function useCollapsibleObserver<T extends HTMLElement>(startZustand: CollapseState): [RefObject<T>, CollapseState] {
    const ref = useRef<T>(null);
    const [observer, setObserver] = useState<MutationObserver | null>(null);
    const [collapseState, setCollapseState] = useState(startZustand);
    const callback = useCallback(() => {
        if (ref.current === null) return;
        else if (ref.current.classList.contains('collapsing')) {
            setCollapseState(CollapseState.aktiv);
        } else if (ref.current.classList.contains('in')) {
            setCollapseState(CollapseState.sichtbar);
        } else {
            setCollapseState(CollapseState.versteckt);
        }
    }, [setCollapseState, ref.current]);

    useEffect(() => {
        setObserver(new MutationObserver(callback));
    }, [setObserver, callback])

    useEffect(() => {
        if (observer === null || ref.current === null) return;
        observer.observe(ref.current, {attributeFilter: ['class']})
        return () => {
            if (observer) {
                observer.disconnect();
            }
        }
    }, [observer, ref.current])

    return [ref, collapseState];
}

//  ================= aktive Trainer =======================

export type GeladeneAktiveTrainer =
    | [LadeZustand.Init]
    | [LadeZustand.Lade]
    | [LadeZustand.Fertig, Trainer[]]
    | [LadeZustand.Fehler]


export function useAktiveTrainer(): [GeladeneAktiveTrainer, LadeKommando] {
    const {apiServer} = useContext(KatalogKontext);
    const [aktiveTrainer, setAktiveTrainer] = useState<GeladeneAktiveTrainer>([LadeZustand.Init]);
    const [ladeZustand] = aktiveTrainer;

    const ladeTrainerKomplett = useCallback(async () => {
        if (ladeZustand === LadeZustand.Init) {
            setAktiveTrainer([LadeZustand.Lade]);
            try {
                const response = await fetch(aktiveTrainerApiUrl(apiServer))
                if (response.ok) {
                    setAktiveTrainer([LadeZustand.Fertig, await response.json()])
                } else {
                    setAktiveTrainer([LadeZustand.Fehler]);
                }
            } catch {
                setAktiveTrainer([LadeZustand.Fehler]);
            }
        }
    }, [ladeZustand, setAktiveTrainer, apiServer])

    return [aktiveTrainer, ladeTrainerKomplett];
}

export function useAktiveTrainerSofort(): GeladeneAktiveTrainer {
    const [aktiveTrainer, ladeAktiveTrainer] = useAktiveTrainer();
    useEffect(() => {ladeAktiveTrainer();});
    return aktiveTrainer;
}


//  ================= Trainer komplett =======================

export type GeladeneTrainerKomplett =
    | [LadeZustand.Init]
    | [LadeZustand.Lade]
    | [LadeZustand.Fertig, TrainerKomplett]
    | [LadeZustand.Fehler]
    | [LadeZustand.NichtGefunden]


export function useTrainerKomplett(id: Guid): [GeladeneTrainerKomplett, LadeKommando] {
    const {apiServer} = useContext(KatalogKontext);
    const [trainerKomplett, setTrainerKomplett] = useState<GeladeneTrainerKomplett>([LadeZustand.Init]);
    const [ladeZustand] = trainerKomplett;

    const ladeTrainerKomplett = useCallback(async () => {
        if (ladeZustand === LadeZustand.Init) {
            setTrainerKomplett([LadeZustand.Lade]);
            try {
                const response = await fetch(trainerKomplettApiUrl(id, apiServer))
                if (response.ok) {
                    setTrainerKomplett([LadeZustand.Fertig, await response.json()])
                } else if (response.status === 404) {
                    setTrainerKomplett([LadeZustand.NichtGefunden])
                } else {
                    setTrainerKomplett([LadeZustand.Fehler]);
                }
            } catch {
                setTrainerKomplett([LadeZustand.Fehler]);
            }
        }
    }, [ladeZustand, setTrainerKomplett, apiServer])

    return [trainerKomplett, ladeTrainerKomplett];
}

export function useTrainerKomplettSofort(id: string) {
    const [trainerKomplett, ladeTrainerKomplett] = useTrainerKomplett(id);
    useEffect(() => {ladeTrainerKomplett();});
    return trainerKomplett;
}

export function useRandomId(prefix?: string) {
    const [randomId] = useState(erzeugeId(prefix));
    return randomId
}