import {ComponentChildren} from "preact";
import {PanelColor} from "./Panel";
import {CollapseState, useCollapsibleObserver, useRandomId} from "../common/hooks";

interface CollapsiblePanelProps {
    parentId: string
    heading: ComponentChildren,
    children: ComponentChildren,
    onButtonClick: () => void
    color?: PanelColor,
}

export function CollapsiblePanel(props: CollapsiblePanelProps) {
    const [collapsibleRef, collapsibleZustand] = useCollapsibleObserver<HTMLDivElement>(CollapseState.versteckt);
    const id = useRandomId();
    const {color = 'default'} = props;
    return (
        <div class={`panel panel-${color}`}>
            <div class="panel-heading clearfix" role="tab">
                <button class="btn btn-primary" style="float: right; margin-left: 1em"
                        data-toggle="collapse" data-parent={`#${props.parentId}`}
                        onClick={props.onButtonClick}
                        href={`#${id}`}>
                    {['mehr', '...', 'weniger'][collapsibleZustand]}
                </button>
                {props.heading}
            </div>
            <div class='panel-collapse collapse' id={id} ref={collapsibleRef} role='tabpanel'>
                <div class='panel-body'>
                    {props.children}
                </div>
            </div>
        </div>
    );
}