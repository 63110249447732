import {Kategorie, KategorieName} from "../common/Veranstaltung";
import {Fragment} from "preact";
import {getKategorieLinks} from "../common/settings";


export function KategorienTags({kategorien}: {kategorien: Kategorie[]}){
    return (
        <Fragment>
            {kategorien.map(kat =>
                    <Fragment>
                        <a href={getKategorieLinks(kat.name as KategorieName)}>
                            <span class="label label-primary">{kat.name}</span>
                        </a>
                        <span> </span>
                    </Fragment>)}
        </Fragment>
    )
}