import {ComponentChildren} from "preact";
import {useContext} from "preact/compat";
import {KatalogKontext} from "./KatalogKontext";

interface Props {
    url: string
    children: ComponentChildren
}

export function ApiServer ({url, children}: Props) {
    const kontext = useContext(KatalogKontext);
    return (
        <KatalogKontext.Provider value={{...kontext, apiServer: url}}>
            {children}
        </KatalogKontext.Provider>
    )
}