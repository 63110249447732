

export function erzeugeZufallszahl (min: number, max: number) {
    return Math.floor(Math.random() * (max - min) ) + min;
}

export function erzeugeId (prefix: string = "id") {
    return `${prefix}_${Date.now()}_${erzeugeZufallszahl(0, 1000000)}`
}

export function wochenTag(date: Date) {
    return  ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."][date.getUTCDay()];
}

function zeroPadded(number: number, padding: number = 2) {
    return number.toString().padStart(padding, '0')
}

export function formatiereDatum(date: Date) {
    const tag  = zeroPadded(date.getUTCDate());
    const monat = zeroPadded(date.getUTCMonth() + 1);
    const jahr = zeroPadded(date.getUTCFullYear(), 4);
    return `${tag}.${monat}.${jahr}`;
}

export function formatiereUhrzeit(date: Date) {
    return `${zeroPadded(date.getUTCHours())}:${zeroPadded(date.getUTCMinutes())}`;
}

export function istGuid(guid: string): boolean {
    return !!guid.match(/^[a-f0-9]{8}-(?:[a-f0-9]{4}-){3}[a-f0-9]{12}$/i);
}