import {useCallback} from "preact/compat";
import {useBsSuccessClass} from "./helfer";
import {useRandomId} from "../common/hooks";

interface TextareaProps {
    value?: string
    gültig?: boolean
    onChange: (value: string) => void
    label: string
    placeholder: string
    rows?: number
    maxLength?: number
}

export function Textarea(props: TextareaProps) {
    const id = useRandomId();
    const gültig = useBsSuccessClass(props.gültig);

    const handleChange = useCallback((ev: Event) => {
        ev.preventDefault();
        props.onChange((ev.target as HTMLTextAreaElement).value);
    }, [props.onChange])

    return (
        <div className={"form-group " + gültig}>
            <label className="control-label" htmlFor={id}>{props.label}</label>
            <textarea className="form-control"
                      id={id}
                      rows={props.rows || 3}
                      maxLength={props.maxLength || 300}
                      placeholder={props.placeholder}
                      onChange={handleChange}>{props.value}</textarea>
        </div>
    )
}