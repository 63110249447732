import {useCallback} from "preact/compat";
import {useBsSuccessClass} from "./helfer";
import {useRandomId} from "../common/hooks";

interface InputProps {
    value?: string
    gültig?: boolean
    type: string
    onChange: (value: string) => void
    label: string
    placeholder: string
}

export function Input(props: InputProps) {
    const id = useRandomId();
    const gültig = useBsSuccessClass(props.gültig)

    const handleChange = useCallback((ev: Event) => {
        ev.preventDefault();
        props.onChange((ev.target as HTMLInputElement).value);
    }, [props.onChange])

    return (
        <div class={"form-group " + gültig}>
            <label class="control-label" htmlFor={id}>{props.label}</label>
            <input type={props.type}
                   class="form-control"
                   id={id}
                   value={props.value}
                   placeholder={props.placeholder}
                   onChange={handleChange}/>
        </div>
    )
}