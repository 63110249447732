
interface ToString {
    toString: () => string
}

interface Props<T> {
    überschrift: string
    liste: T[]
}

export function ListeMitÜberschrift<T extends ToString>({überschrift, liste}: Props<T>) {
    if (liste === null) return null
    return (
        <section>
            <h3>{überschrift}</h3>
            <ul>
                {liste.map((item) =>
                    <li>{item.toString()}</li>
                )}
            </ul>
        </section>
    )
}