import {GeladeneVeranstaltungsDaten, LadeZustand} from "../common/hooks";
import {VeranstaltungsDetailBody} from "./VeranstaltungsDetailBody";
import {Sanduhr} from "../common/Sanduhr";
import {Panel} from "../widgets/Panel";
import {Fragment} from "preact";
import {VeranstaltungsDetailHeader} from "./VeranstaltungsDetailsHeader";


interface Props {
    geladeneDaten: GeladeneVeranstaltungsDaten
    zeigeHeader?: boolean
}

export function VeranstaltungsDetails ({geladeneDaten, zeigeHeader = true}: Props) {
    const [ladeZustand, veranstaltung] = geladeneDaten;

    switch(ladeZustand) {
        case LadeZustand.Init:
        case LadeZustand.Lade:
            return <Sanduhr was={"Veranstaltung"}/>
        case LadeZustand.Fehler:
            return (
                <Panel color='danger' heading='Fehler beim laden der Veranstaltung'>
                        Die Veranstaltung konnte leider nicht geladen werden. Bitte versuchen sie es zu
                        einem späteren Zeitpunkt erneut.
                </Panel>
            )
        case LadeZustand.NichtGefunden:
            return (
                <Panel color='info' heading='Unbekannte Veranstaltung'>
                        Die von ihnen angefragte Veranstaltung ist uns unbekannt.
                </Panel>
            )
        case LadeZustand.Fertig:
            return (
            <Fragment>
                {zeigeHeader ? <VeranstaltungsDetailHeader veranstaltung={veranstaltung}/> : null}
                <VeranstaltungsDetailBody veranstaltung={veranstaltung}/>
            </Fragment>
        );
    }
}