import {Termine} from "../common/Veranstaltung";
import {EinzelTerminTabelle} from "./EinzelTerminTabelle";
import {ListeMitÜberschrift} from "../common/ListeMitÜberschrift";

interface Props {
    termine: Termine
    unterrichtszeit: string
}

export function Termine ({termine, unterrichtszeit} : Props) {
    return (
        <section>
            <h3>Termine</h3>
            <p>
                Die Veranstaltung findet {termine.online? "online" : `in ${termine.postleitzahl} ${termine.stadt}`} statt
                und umfasst {unterrichtszeit} Unterricht.
            </p>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <EinzelTerminTabelle einzelTermine={termine.einzelTermine}></EinzelTerminTabelle>
                </div>
                {termine.notizen.length === 0 ? null :
                    <div class="col-sm-12 col-md-5">
                        <ListeMitÜberschrift überschrift="Notizen" liste={termine.notizen}></ListeMitÜberschrift>
                    </div>
                }
            </div>
        </section>
    )
}