import {ComponentChildren} from "preact";

export type PanelColor = "primary" | "success" | "info" | "warning" | "danger" | "default"

export interface PanelProps {
    color?: PanelColor
    heading?: ComponentChildren
    children: ComponentChildren
}

export function Panel ({heading, children, color = "default"}: PanelProps) {
    return (
        <div class={`panel panel-${color}`}>
            {heading ? <div class={'panel-heading'}>{heading}</div> : null}
            <div class={'panel-body'}>{children}</div>
        </div>
    )
}