import {Zustand} from "../common/Veranstaltung";

function berechneLabel(zustand: Zustand) {
    switch (zustand) {
        case Zustand.Veröffentlicht: return 'Buchbar';
        case Zustand.Laufend: return 'Begonnen';
        case Zustand.Beendet: return 'Beendet';
        case Zustand.Abgesagt: return 'Abgesagt';
        case Zustand.Archiviert: return 'Archiviert';
        case Zustand.Unveröffentlicht: return 'Unveröffentlicht';
        default: return 'Unbekannter Zustand';
    }
}

function berechneLabelKlasse (zustand: Zustand) {
    switch (zustand) {
        case Zustand.Veröffentlicht: return 'label-success';
        case Zustand.Laufend: return 'label-info';
        case Zustand.Beendet: return 'label-default';
        case Zustand.Abgesagt: return 'label-danger';
        default: return 'label-primary';
    }
}


export function Zustandslabel({zustand}: {zustand: Zustand}){
    return <span class={`label ${berechneLabelKlasse(zustand)}`}>{berechneLabel(zustand)}</span>
}