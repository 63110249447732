import {EinzelTermin, OrtsModalität} from "../common/Veranstaltung";
import {formatiereDatum, formatiereUhrzeit, wochenTag} from "../common/helfer";
import {Fragment} from "preact";

function formatiereOrt(ort: OrtsModalität, ortkürzel: string | undefined) {
    if (ort === OrtsModalität.Online) return "Online"
    if (ort === OrtsModalität.Präsenz) return ortkürzel!
    if (ort === OrtsModalität.Hybrid) return `${ortkürzel!} oder Online`
}

function istDerGleicheTag(tag1: Date, tag2: Date){
    let d1 = new Date(tag1);
    d1.setUTCHours(0,0,0,0);
    let d2 = new Date(tag2);
    d2.setUTCHours(0,0,0,0);
    return d1.getTime() === d2.getTime();
}

function LeerZeile({zeilen}: {zeilen: number}){
    const spalten = [];
    for (let i = 0; i < zeilen; i++) {
        spalten.push(<td></td>)
    }
    return (
        <tr style="background-color: #f9f9f9;">
            {spalten}
        </tr>
    )
}

export function EinzelTerminTabelle({einzelTermine}: { einzelTermine: EinzelTermin[] }) {
    const keinTerminÜberNacht = einzelTermine.every(et => istDerGleicheTag(new Date(et.start), new Date(et.ende)));
    let vorherigesEnde: Date | null = null;
    return (
        <table className="table table-condensed table-bordered">
            <thead>
            <tr>
                <th className="text-center" colSpan={3}>Start</th>
                <th className="text-center" colSpan={keinTerminÜberNacht ? 1 : 3}>Ende</th>
            </tr>
            <tr>
                <th className="text-center">Tag</th>
                <th className="text-center">Datum</th>
                <th className="text-center">Uhrzeit</th>
                {keinTerminÜberNacht ? null : <Fragment>
                    <th className="text-center">Tag</th>
                    <th className="text-center">Datum</th>
                </Fragment>}
                <th className="text-center">Uhrzeit</th>
                <th className="text-center">Ort</th>
            </tr>
            </thead>
            <tbody>
            {einzelTermine.map((et) => {


                const start = new Date(et.start);
                const ende = new Date(et.ende);
                const gleicherTag = istDerGleicheTag(start, ende);

                const neuerBlock = vorherigesEnde && (start.getTime() - vorherigesEnde.getTime()) > 1000*60*60*24;

                const zeilen = (
                    <Fragment>
                        {neuerBlock? <LeerZeile zeilen={keinTerminÜberNacht ? 5 : 7}></LeerZeile> : null}
                        <tr>
                            <td className="text-center">{wochenTag(start)}</td>
                            <td className="text-center">{formatiereDatum(start)}</td>
                            <td className="text-center">{formatiereUhrzeit(start)}</td>
                            {keinTerminÜberNacht ? null : <Fragment>
                                <td className="text-center">{gleicherTag? "—" : wochenTag(ende)}</td>
                                <td className="text-center">{gleicherTag? "—" : formatiereDatum(ende)}</td>
                            </Fragment>}
                            <td className="text-center">{formatiereUhrzeit(ende)}</td>
                            <td className="text-center">{formatiereOrt(et.ort, et.ortkürzel)}</td>
                        </tr>
                    </Fragment>
                )

                vorherigesEnde = ende;
                return zeilen
            })}
            </tbody>
        </table>
    )
}