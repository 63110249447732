
export type Guid = string;
export type DateTime = string;

export enum Zustand {
    Unveröffentlicht = 1,
    Veröffentlicht = 2,
    Laufend = 3,
    Beendet = 4,
    Abgesagt = 5,
    Archiviert = 6
}

export interface VeranstaltungAuszug {
    id: Guid,
    titel: string,
    zustand: Zustand,
    start: DateTime,
    ende: DateTime,
    ort: string,
    kategorien: Kategorie[]
}

export interface Veranstaltung {
    id: Guid,
    titel: string,
    zustand: Zustand,
    ausschreibungHtml: string,
    termine: Termine,
    unterrichtszeit: string,
    preis: Preiskomponente[]
    rabatte: string[]
    trainer: Trainer[]
    kooperation: Kooperation,
    zertifizierungsbedingungen: string[],
    kategorien: Kategorie[],
    absagegrund: string | null
}

export interface Termine {
    einzelTermine: EinzelTermin[],
    notizen: string[],
    online: boolean,
    stadt?: string,
    postleitzahl?: string
}

export interface EinzelTermin {
    start: DateTime,
    ende: DateTime,
    ort: OrtsModalität,
    ortkürzel?: string
}

export const enum OrtsModalität{
    Online = 1,
    Präsenz = 2,
    Hybrid = 3
}

export interface Preiskomponente {
    kosten: string,
    beschreibung: string
}

export interface Trainer {
    id: Guid,
    vorname: string,
    nachname: string
}

export interface TrainerKomplett extends Trainer {
    qualifikationen: string[],
    weiterbildungen: string[],
    erfahrungen: string[]
}

export interface Kooperation {
    vorhanden: boolean,
    name?: string,
    website?: string,
    buchungslink?: string,
    bildungsurlaub?: boolean
}

export const enum KategorieTyp {
    Einstellbar = 1,
    Automatisch = 2,
    HashTag = 3
}

export type EinstellbareKategorieName =
    | "Ausbildung"
    | "Seminar"
    | "Vortrag"
    | "Outdoor"
    | "Blockseminar"
    | "Wochenkurs"
    | "IN"
    | "ICI"
    | "WHO"
    | "DVNLP"
    | "SocietyOfNlp"
    | "Nlpaed"
    | "ProC"
    | "NlpBasic"
    | "NlpPractitioner"
    | "NlpMaster"
    | "NlpAdvMaster"
    | "Trainer"
    | "LernCoach"
    | "LernCoachMaster"
    | "HypnoCoach"
    | "SystemischerCoach"
    | "Mediator"

export interface  EinstellbareKategorie {
    typ: KategorieTyp.Einstellbar
    name: EinstellbareKategorieName
}

export type AutomatischeKategorieName =
    | "NurOnline"
    | "NurPräsenz"
    | "Hybrid"
    | "BlendedLearning"
    | "Abends"
    | "Vormittags"
    | "Werktags"
    | "Wochenende"
    | "AbendsUndWochenende"
    | "VormittagsUndWochenende"
    | "Gratis"

export interface  AutomatischeKategorie {
    typ: KategorieTyp.Automatisch
    name: AutomatischeKategorieName
}

export interface HashTag {
    typ: KategorieTyp.HashTag
    name: string
}

export type KategorieName = EinstellbareKategorieName | AutomatischeKategorieName

export type Kategorie = EinstellbareKategorie | AutomatischeKategorie | HashTag





