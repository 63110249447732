import {Trainer} from "../common/Veranstaltung";
import {Fragment} from "preact";
import {trainerDetailsUrl, trainerKopfPlatzhalterUrl, trainerKopfUrl} from "../common/settings";

export function TrainerKöpfe({trainer}: { trainer: Trainer[] }) {
    return (
        <section>
            <h3>Trainer</h3>
            <div>
                {trainer.map(t =>
                    <Fragment>
                        <div class="well well-sm text-center" style="display:inline-block; margin-right: .2em;">
                            <p>
                                <img src={trainerKopfUrl(t.id)} class="center-block" alt={`${t.vorname} ${t.nachname}`}
                                     onError={(ev) => (ev.target as HTMLImageElement).src = trainerKopfPlatzhalterUrl}/>
                            </p>
                            <a href={trainerDetailsUrl(t.id)}>
                                {t.vorname} {t.nachname}
                            </a>
                        </div>
                    </Fragment>)}
            </div>
        </section>
    )
}