import {Veranstaltung, Zustand} from "../common/Veranstaltung";
import {Fragment} from "preact";
import {Zertifizierungsbedingungen} from "./Zertifizierungsbedingungen";
import {Termine} from "./Termine";
import {Ausschreibung} from "./Ausschreibung";
import {Investition} from "./Investition";
import {Kooperationspartner} from "./Kooperationspartner";
import {WeitereInfos} from "./WeitereInfos";
import {TrainerKöpfe} from "./TrainerKöpfe";
import {Anmeldung} from "../anmeldung/Anmeldung";

export interface VeranstaltungsCardBodyProps{
    veranstaltung: Veranstaltung
}

export function VeranstaltungsDetailBody({veranstaltung}: VeranstaltungsCardBodyProps) {
    return (
        <Fragment>
            <Kooperationspartner partner={veranstaltung.kooperation}/>
            <Ausschreibung html={veranstaltung.ausschreibungHtml}/>
            <Termine termine={veranstaltung.termine} unterrichtszeit={veranstaltung.unterrichtszeit}/>
            <TrainerKöpfe trainer={veranstaltung.trainer}/>
            <Zertifizierungsbedingungen bedingungen={veranstaltung.zertifizierungsbedingungen}/>
            <Investition preis={veranstaltung.preis} rabatte={veranstaltung.rabatte}/>
            <WeitereInfos kategorien={veranstaltung.kategorien}/>
            {veranstaltung.zustand === Zustand.Veröffentlicht ? <Anmeldung veranstaltung={veranstaltung}/> : null}
        </Fragment>
    )
}